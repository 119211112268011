import { handleMessage } from './Messaging'

const setWidgetDefaults = (event, store) => {
  store.STORE_WIDGET_SETTINGS(event.data.data)
  const groupId = event.data?.data?.groups?.[0]
  if (groupId) {
    store.SET_GROUP(groupId)
  }
}

const handlePostMessage = (event, store, router, route) => {
  console.log('handlePostMessage: ', event.data)
  if (event?.data?.type === 'widget_settings') {
    fetch('/ws/default-strings.json')
      .then(response => {
        response.json().then(defaultStrings => {
          console.log('DEFAULT STRINGS:::', defaultStrings)
          store.STORE_DEFAULT_SETTINGS(defaultStrings)
          setWidgetDefaults(event, store)

          if (!store.getWidgetSettings) {
            console.warn('NO WIDGET SETTINGS')
            return
          }

          store.getStoredConversationId()
            .then(storedConversationId => {
              if (
                store.getWidgetSettings.channels.includes('webchat') &&
                !store.getWidgetSettings.channels.includes('whatsapp') &&
                !store.getWidgetSettings.channels.includes('sms') &&
                !storedConversationId
              ) {
                router.replace(`/widget/chat/${route.params.businessCode}/${route.params.group}`)
              }
            })
        })
      })
      .catch(err => {
        console.error(err)
        setWidgetDefaults(event, store)
      })
    
    return
  }
}

export default handlePostMessage
