import dayjs from 'dayjs'
import { Checkout, DateEntity, Invoice } from './DTOs'

export const getInvoiceStatus = (row: Invoice | Checkout | undefined) => {
  if (!row) {
    return 'UNKNOWN'
  }
  // if (row.paymentTimestamp && row.paymentAmount === row.cachedTotalAmount) {
  if (row.paymentAmount === row.cachedTotalAmount) {
    return 'PAID'
  }
  const currentDate = dayjs()
  const dueDate = (row.invoiceDueDate || row.checkoutDate) as DateEntity
  if (!dueDate) {
    return 'UNKNOWN'
  }
  const invoiceDate = dayjs(`${dueDate.day}-${dueDate.month}-${dueDate.year}`, 'D-M-YYYY')
  if (row.paymentAmount < row.cachedTotalAmount && invoiceDate.isBefore(currentDate, 'day')) {
    return 'OVERDUE'
  }
  if (row.paymentAmount < row.cachedTotalAmount) {
    return 'DUE'
  }
}

export const getCheckoutStatus = (row: Invoice | Checkout | undefined) => {
  if (!row) {
    return 'UNKNOWN'
  }
  if (row.paymentAmount === row.cachedTotalAmount) {
    return 'PAID'
  } else {
    return 'DUE'
  }
}

export const getPaymentStatusColor = (status: 'PAID' | 'DUE' | 'OVERDUE' | 'UNKNOWN' | undefined) => {
  switch (status) {
    case 'UNKNOWN': {
      return 'gray-400'
    }
    case 'PAID': {
      return 'lime-500'
    }
    case 'DUE': {
      return 'orange-500'
    }
    case 'OVERDUE': {
      return 'red-500'
    }
    default: {
      return 'purple-500'
    }
  }
}
export const getStatusLabel = (status: 'PAID' | 'DUE' | 'OVERDUE' | 'UNKNOWN' | undefined) => {
  switch (status) {
    case 'UNKNOWN': {
      return 'Unknown'
    }
    case 'PAID': {
      return 'Paid'
    }
    case 'DUE': {
      return 'Unpaid'
    }
    case 'OVERDUE': {
      return 'Overdue'
    }
    default: {
      return 'UNKNOWN'
    }
  }
}

export const formatShortDate = (date: DateEntity) => {
  const formattedDate = dayjs(`${date.year}-${date.month}-${date.day}`, 'YYYY-M-D').format('DD-MM-YYYY')
  return formattedDate
}

export const dateAsMs = (date: DateEntity) => {
  const formattedDate = dayjs(`${date.year}-${date.month}-${date.day}`, 'YYYY-M-D').valueOf()
  return formattedDate
}

export const Currencies = {
  GBP: '£',
  EUR: '€',
  USD: '$'
}

export function isDefined(value: string | number | undefined | null) {
  return value !== null && value !== undefined && !isNaN(value)
}

function iOSSafari(userAgent: string) {
  return /iP(ad|od|hone)/i.test(userAgent) &&
    /WebKit/i.test(userAgent) &&
    !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent))
}

export function detectWhatsapp(phone: number, text: number) {
  const uri = `https://wa.me/${encodeURIComponent(
    phone
  )}?text=${encodeURIComponent(text)}`

  function onIE() {
    return new Promise((resolve) => {
      window.navigator.msLaunchUri(
        uri,
        () => resolve(true),
        () => resolve(false)
      )
    })
  };

  function notOnIE() {
    return new Promise((resolve) => {
      window.open(uri)
      resolve(true)
    })
  }

  // @ts-ignore
  return window.navigator.msLaunchUri ? onIE() : notOnIE()
};

export function isUnsupportedBrowser() {
  return isIOSSafari()
}

export function isIOSSafari () {
  const ua = window.navigator.userAgent
  return iOSSafari(ua)
}

export function sendSMS(phone: number, text: number) {
  const uri = `sms:${encodeURIComponent(phone)}&body=${encodeURIComponent(text)}`
  window.open(uri, '_top')
}

export function generateId(len: number) {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0')
}

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function loadAvatar(img: HTMLImageElement, agent: User) {
  img.onerror = null
  console.log('Handle img error', img.src, agent)
  switch (agent.level) {
    case 80:
      img.src = '/reply-arrow-icon.svg'
      img.style.width = '20px'
      img.style.height = '20px'
      img.parentElement.classList.add('bg-blue-100')
      break

    case 90:
      img.src = '/ai.svg'
      img.parentElement.classList.remove('rounded-full')
      img.style.width = '18px'
      img.style.height = '18px'
      break
    default:
      img.src = '/anonymous-user.svg'
  }
}