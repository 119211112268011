interface State {
  root: string;
  clean: string;
  payments: string;
  xero: string;
  APIs: { [key: string]: string; };
}

interface REST {
  setDomainRoot: () => void;
  getAPI: (api: string, which: 'payments' | 'xero' | 'clean') => string;
  getRoot: () => string;
  getPublicRoot: () => string;
  getPaymentsDomainRoot: () => string;
  isStaging: () => boolean;
  isDevelop: () => boolean;
  isLocalhost: () => boolean;
}

const localhost = 'https://localhost'

const props: State = {
  root: '', // this is set in the axios boot file as the app initializes
  clean: '',
  payments: 'rest/',
  xero: 'xero/',
  APIs: {
    login: 'auth/v1/login', // PUT, email, password, device (optional)
    logout: 'auth/v1/logout', // PUT
    upgrade: 'auth/v1/guest', // PUT
    resetPassword: 'auth/v1/reset', // PUT, email address
    authPing: 'auth/v1/token', // PUT

    // USERS
    getUsers: 'users/v1/', // GET, :filter/:key // GIANLUCA to add filter for user type - customer !== user in terms of UI representation

    // BUSINESSES
    getBusiness: 'businesses/v1/code/', // GET, :filter/:key

    // INVOICES
    getInvoices: 'invoices/v1/', // GET, :businessCode/:groupId/:filter/:key

    // CONVERSATIONS
    getConversations: 'conversations/v1/', // GET, :businessCode/:groupId/:filter/:key

    // MESSAGES
    getMessages: 'messages/v1/', // GET, :businessCode/:groupId/:conversation/:filter/:key
    putMessage: 'communications/v1/', // PUT/POST, :businessCode/:group/:conversation/:channel/:action

    // CREZCO
    getCrezcoBanks: 'crezco/banks/', // GET, :country (GB)
    getCrezcoPaymentLink: 'crezco/payment/custom/', // GET, :businessCode/:invoiceId/:country/:bankCode
    getBankIntent: 'intent/v1/', // GET, :businessCode/:invoiceId/bank

    // SELFSERVICE
    validateEmail: 'selfservice/v1/validate/email',
    validatePhone: 'selfservice/v1/validate/phone',
    updatePassword: 'selfservice/v1/update/password',

    // FILES
    file: 'files/v1/', // PUT > :businessCode -/- GET > :businessCode/:filter/:key -/- DELETE :businessCode/:id

    // RESOURCE
    resource: 'resource/v1/' // PUT > :businessCode -/- GET > :businessCode/:key
  }
}

function setDomainRoot(): void {
  const restRoot = (isStaging() ? 'stagingbackend' : 'backend')
  const root = `https://${restRoot}.converso.io/`
  props.root = root
  console.log('setDomainRoot: ', root)
}

function getPublicRoot(): string {
  if (isLocalhost()) {
    return 'https://localhost'
  }
  return `https://${isStaging() ? 'staging' : ''}customer.converso.io`
}

function getAPIEndpoint(api: string): string {
  const _api: string = props?.APIs[api]
  return _api
}

function getAPI(api: string, which: 'payments' | 'xero' | 'clean'): string {
  const _api: string = getAPIEndpoint(api)
  const domainRoot = `${props.root}${props[which]}`
  console.log('getAPI: ', _api, domainRoot)
  return `${domainRoot}${_api}`
}

function getRoot() { return props.root }

function getPaymentsDomainRoot() {
  return `${props.root}${props.payments}`
}

const developDomains = [
  'https://develop.customer.converso.io',
  'https://develop.webapp.converso.io',
  localhost
]

const stagingDomains = [
  'https://staging.customer.converso.io',
  'https://staging.webapp.converso.io',
  localhost
]
function isStaging() {
  return document ? [...stagingDomains, ...developDomains].includes(document.location.origin) : false
}
function isDevelop() {
  return document ? developDomains.includes(document.location.origin) : false
}
function isLocalhost() { return document && document.location.origin === localhost }

const rest: REST = {
  setDomainRoot, // used by axios boot to set the root API path
  getAPI, // return me an API constructed of the correct route and path
  getRoot, // returns a reference to the root set at boot
  getPublicRoot, // returns the public root for viewing the portal
  getPaymentsDomainRoot, // returns the payments route
  isStaging, // check if we are running on staging
  isDevelop,
  isLocalhost // check if we are running locally
}

export default rest
