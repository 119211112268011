export default defineNuxtPlugin((_nuxtApp) => {
  const store = usePortalStore()
  const key = 'console-enabled'+ store.getLocalStorageSuffix
  const enabledKey: string | null = localStorage.getItem(key)
  let enabled: boolean = enabledKey === 'true';
  
  window.console = new Proxy(window.console, {
    get(console, prop){
      if(prop === 'enabled'){
        return `console enabled: ${enabled}`
      }
      if(!enabled && typeof console[prop as keyof typeof console] === 'function' && console.hasOwnProperty(prop)){
        return function(){}
      }
      return Reflect.get(...arguments);
    },
    set(console, prop, val){
      if(prop === 'enabled'){
        enabled = val;
        try{
          localStorage.setItem(key, val);
        }catch(_){}
        return true;
      }
      return Reflect.set(...arguments);
    }
  });

  switch (enabledKey) {
    case 'true':
      window.console.enabled = true
      break

    case 'false':
      window.console.enabled = false
      break

    default:
      window.console.enabled = rest.isDevelop()
  }
})