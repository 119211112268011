import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_JRId4KOeUL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaults_ejC916ejE3 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-schema-org/dist/runtime/app/plugins/defaults.js";
import init_8zxuXEdLTw from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-schema-org/dist/runtime/app/plugins/init.js";
import logging_t1Zzu96LwL from "/opt/atlassian/pipelines/agent/build/plugins/logging.ts";
import ofetch_TmnSnS1Swe from "/opt/atlassian/pipelines/agent/build/plugins/ofetch.ts";
import defaults_M8ptihKv0z from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  defaults_ejC916ejE3,
  init_8zxuXEdLTw,
  logging_t1Zzu96LwL,
  ofetch_TmnSnS1Swe,
  defaults_M8ptihKv0z
]