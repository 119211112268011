<template>
  <div
    ref="onlineStatus"
    class="online-status p-4 w-full flex justify-center transition duration-500 absolute h-18 z-10 text-white text-lg"
    :class="{
      topClass,
      'bg-red-500': !isOnline,
      'bg-green-500': isOnline
    }"
  >
    <p v-if="isOnline">You are now online!</p>
    <p v-else>You are currently offline ;-(</p>
  </div>
</template>

<script setup lang="ts">
const store = usePortalStore()
const topClass = '-translate-y-[18rem]'
const isOnline = computed(() => store.getIsOnline)
const onlineStatus = ref<HTMLDivElement>()

function hideAfterDelay () {
  setTimeout(() => {
    onlineStatus.value?.classList.add(topClass)
  }, 4000)
}

watch(isOnline, newVal => {
  if (newVal) {
    hideAfterDelay()
    store.throttledCheckDataFreshness(true)
  } else {
    onlineStatus.value?.classList.remove(topClass)
  }
})

onMounted(() => {
  onlineStatus.value?.classList.add(topClass)
})
</script>