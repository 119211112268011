<template>
  <ModalPopup
    ref="login"
    :title="mode === 'signin' ? 'Sign in' : 'Forgot your password?'"
    :inline="inline"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <template v-if="!store.wasAnonymous && store.wasBootedOut">
          <h3>You have been logged out.</h3>
          <p>Please sign in again</p>
        </template>
        <label
          class="block text-xs text-gray-500"
          for="email"
        >Email</label>
        <input
          v-model="username"
          type="text"
          placeholder="Email"
          class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
        >
      </div>
      <div class="mt-4">
        <label class="block text-xs text-gray-500">Password</label>
        <input
          v-if="mode === 'signin'"
          v-model="password"
          type="password"
          placeholder="Password"
          class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
        >
      </div>
    </template>
    <template #buttons>
      <template v-if="mode === 'signin' && !resetSuccess">
        <button
          class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900"
          @click.stop.prevent="signIn"
        >
          Sign in to your account
        </button>
        <a
          href="#"
          class="text-sm mt-10 text-center text-blue-600 hover:underline"
          @click.stop="mode = 'reset'"
        >Forgot password?</a>
      </template>

      <template v-if="mode === 'reset'">
        <button
          v-if="!resetSuccess"
          class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900"
          @click.stop.prevent="reset"
        >
          Reset
        </button>
        <a
          v-if="!resetSuccess"
          href="#"
          class="text-sm text-blue-600 hover:underline"
          @click.stop="mode = 'signin'"
        >Sign in</a>

        <p
          v-if="resetSuccess"
          class="p-md bg-yellow-100 text-yellow-900"
        >
          Please check your email address for a new password and sign in again.
        </p>
      </template>
    </template>
  </ModalPopup>
</template>

<script setup>
import { ref, computed } from 'vue'
const route = useRoute()
const props = defineProps({
  inline: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close'])

const store = usePortalStore()

const username = computed({
  get() {
    return store.username
  },
  set(val) {
    store.SET_USERNAME(val)
  }
})
const password = ref('')
const mode = ref('signin')
const resetSuccess = ref(false)
const businessCode = computed(() => store.getBusinessCode)

const setUsername = (newUN) => {
  store.SET_USERNAME(newUN)
}

const signIn = async () => {
  await store.signIn(username.value, password.value)

  password.value = ''
  emit('close')
}

const reset = async (un) => {
  if (!un) {
    return
  }

  setUsername(un)
  const url = rest.getAPI('resetPassword', 'payments')
  const response = await this.$axios.put(url, {
    username: un,
    businessCode: businessCode.value
  })

  if (response) {
    resetSuccess.value = true

    setTimeout(() => {
      resetSuccess.value = false
      mode.value = 'signin'
    }, 4000)
  }
}

defineExpose({
  signIn,
  password
})
</script>

