import { ofetch } from 'ofetch'

export default defineNuxtPlugin((_nuxtApp) => {
  globalThis.$fetch = ofetch.create({
    onRequest(args) {
      const { options, request } = args
      const store = usePortalStore()
      const headers: { token?: string, notifications?: string } = {}
      const token = store.authToken || store.getLoggedInUser?.token
      if (
        token &&
        !String(request).includes('/login')
      ) {
        headers.token = token
      } else {
        console.log('No token in store: ', store)
      }

      options.headers = headers
    },
    onRequestError({ error }) {
      console.error(error)
    },
    onResponseError({ response }) {
      console.error(response)
      if (
        response.status === 401
      ) {
        const store = usePortalStore()
        if (response.url.includes(rest.getAPI('authPing', 'payments'))) {
          // token check failed
          // get new token
          store.requestLogin()
        } else {
          if (response._data === 'Error: Invalid login credentials.') {
            // re-login using stored credentials failed
            store.anonymousLogin()
          } else {
            store.checkAuthToken(true)
          }
        }
      }
    }
  })
})